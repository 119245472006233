import { useEffect, useState } from 'react';
import styles from './loader.module.scss';

export const Loader = () => {
    const [percents, setPercents] = useState(0);

    useEffect(() => {
        const speedLoadingInterval = setInterval(() => {
            setPercents(prev => {
                if (prev === 95) {
                    clearInterval(speedLoadingInterval);

                    return prev;
                } else {
                    return prev + 1;
                }
            });
        }, 250);
    }, [])

    return (
        <div className={styles['loader']}>
            <div className={styles['loader__caption']}>interior generation {percents}%</div>
            <div className={styles['loader__line']}>
                <span style={{ width: `${percents}%` }} className={styles['loader__progress']}></span>
            </div>
        </div>
    )
}