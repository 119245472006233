import { createContext, useState } from "react";

import { Configurator } from "../configurator/configurator";
import { AiDesigner } from "../ai-designer/ai-designer";
import { Placeholder } from "../placeholder/placeholder";

const defaultConfiguratorContext = {
    image: "",
};

export const ConfiguratorContext = createContext(defaultConfiguratorContext);

export const App = () => {
    const [isOpenAiDesigner, setIsOpenAiDesigner] = useState(false);

    const isDesktop = () => {
        return window.matchMedia('(min-width: 1200px)').matches;
    }

    return (
        <ConfiguratorContext.Provider value={defaultConfiguratorContext}>
            <div className="site-wrapper">
                {
                    isDesktop() ? (
                        <>
                            <Configurator isOpenAiDesigner={isOpenAiDesigner} setIsOpenAiDesigner={setIsOpenAiDesigner} />
                            <AiDesigner isOpenAiDesigner={isOpenAiDesigner} setIsOpenAiDesigner={setIsOpenAiDesigner} />
                            </>
                    ) : <Placeholder />
                }
            </div>
        </ConfiguratorContext.Provider>
    );
};
